import axios from 'axios'
import { MERCHANT_ID } from '../utils/Constants'

export function create (token) {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT_API}`,
    timeout: 30000,
    headers: { Authorization: 'Bearer ' + token }
  })
  instance.interceptors.response.use(rsp => rsp, err => {
    if (err.response.status === 401 || err.response.status === 403) {
      window.location = '/login'
    } else {
      throw err
    }
  })
  return instance
}

export async function login (user, password) {
  try {
    const url = `${process.env.REACT_APP_ENDPOINT_API}users/login`
    const rsp = await axios.post(url, {
      userName: user,
      password: password,
      merchantId: MERCHANT_ID
    })
    return rsp.data
  } catch (error) {
    if (error.isAxiosError) {
      return error.response.data
    }
    return {
      error
    }
  }
}
