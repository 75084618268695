import { Select, SIZE } from 'baseui/select'
import { FormControl } from 'baseui/form-control'
import { DatePicker, ORIENTATION } from 'baseui/datepicker'
import { Input } from 'baseui/input'
import { Button } from 'baseui/button'
import { useState } from 'react'
import { startOfDay, addDays, isEmptyOrSpaces, TIME_ZONES } from '../utils/Utils'
import { Clock } from './Clock'
import { styled } from 'styletron-react'

const Container = styled('div', {
  padding: '1em'
})

const trimedString = x => {
  if (isEmptyOrSpaces(x)) return ''
  return x.trim()
}

const normalizedString = x => {
  if (isEmptyOrSpaces(x)) return undefined
  return x.trim()
}

const merchantOptions = [
  { id: undefined, label: 'All' },
  { id: 'LOTTONOW', label: 'LOTTONOW' },
  { id: 'XCG', label: 'XCG' }
]

const gameOptions = [
  { id: undefined, label: 'All' },
  { id: 'fruit-mania', label: 'Fruit Mania' },
  { id: 'euro-cup', label: 'Euro Cup' },
  { id: 'candy-burst', label: 'Candy Burst' },
  { id: 'fanatic-ape', label: 'Fanatic Ape' }
]

export function SearchPanel ({ token, user, isLoading, onSearchArgsChanged }) {
  const [searchArgs, setSearchArgs] = useState({
    merchant: [merchantOptions[0]],
    user: trimedString(user),
    dateRange: [],
    game: [gameOptions[0]]
  })
  return (
    <Container>
      <FormControl>
        <Clock timeZone={TIME_ZONES.Nairobi} />
      </FormControl>

      <FormControl label='Merchant'>
        <Select
          options={merchantOptions}
          clearable={false}
          labelKey='label'
          valueKey='id'
          onChange={({ value }) => setSearchArgs({
            ...searchArgs,
            merchant: value
          })}
          placeholder='Merchant'
          value={searchArgs.merchant}
          size={SIZE.compact}
        />
      </FormControl>

      <FormControl label='Game'>
        <Select
          options={gameOptions}
          clearable={false}
          valueKey='id'
          labelKey='label'
          onChange={({ value }) => setSearchArgs({
            ...searchArgs,
            game: value
          })}
          placeholder='Select Game'
          value={searchArgs.game}
          size={SIZE.compact}
        />
      </FormControl>

      <FormControl label='Date range'>
        <DatePicker
          value={searchArgs.dateRange}
          size={SIZE.compact}
          onChange={({ date }) => {
            setSearchArgs({
              ...searchArgs,
              dateRange: date
            })
          }}
          range
          orientation={ORIENTATION.horizontal}
          monthsShown={2}
          quickSelect
          timeSelectStart
          timeSelectEnd
        />
      </FormControl>

      <FormControl label='User'>
        <Input
          size={SIZE.compact}
          placeholder='User ID'
          value={searchArgs.user}
          clearable
          onChange={x => setSearchArgs({ ...searchArgs, user: trimedString(x.target.value) })}
        />
      </FormControl>

      <FormControl>
        <Button
          isLoading={isLoading}
          size={SIZE.compact}
          onClick={() => {
            onSearchArgsChanged({
              merchant: searchArgs.merchant && searchArgs.merchant[0]?.id,
              game: searchArgs.game && searchArgs.game[0]?.id,
              user: normalizedString(searchArgs.user),
              startDate: searchArgs.dateRange.length > 0 ? startOfDay(searchArgs.dateRange[0]).toISOString() : undefined,
              endDate: searchArgs.dateRange.length > 1 ? startOfDay(addDays(searchArgs.dateRange[1], 1)).toISOString() : undefined
            })
          }}
        >Search
        </Button>
      </FormControl>

    </Container>
  )
}
