import { Spinner } from 'baseui/spinner'

export default function Loading ({ label }) {
  return (
    <div>
      <Spinner />
      <div>{label}</div>
    </div>
  )
}
