import { useContext, useState } from 'react'
import {
  AppNavBar,
  setItemActive
} from 'baseui/app-nav-bar'
import {
  ChevronDown,
  Delete,
  Overflow,
  CheckIndeterminate
} from 'baseui/icon'
import { MdDashboard, MdPeopleOutline, MdUpdate, MdTrendingUp } from 'react-icons/md'
import { useHistory, useLocation } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import { Footer } from './Footer'
import { AppContext } from '../context/AppContext'
import { ACTIONS } from '../utils/Constants'

export function AppLayout ({ title, children }) {
  const darkMode = useDarkMode(false)
  const { state, dispatch } = useContext(AppContext)
  const history = useHistory()
  const location = useLocation()
  const [mainItems, setMainItems] = useState([
    { active: location.pathname === '/', icon: MdDashboard, label: 'Dashboard', href: '/' },
    { active: location.pathname === '/slots/transactions', icon: MdUpdate, label: 'Game Transactions', href: '/slots/transactions' },
    {
      icon: ChevronDown,
      label: 'Reports',
      active: location.pathname.startsWith('/reports'),
      href: '/reports/dailysummary',
      navExitIcon: Delete,
      children: [
        {
          icon: MdTrendingUp,
          label: 'Daily',
          active: location.pathname === '/reports/dailysummary',
          href: '/reports/dailysummary'
        },
        {
          icon: MdTrendingUp,
          label: 'Monthly',
          active: location.pathname === '/reports/monthlysummary',
          href: '/reports/monthlysummary'
        },
        {
          icon: MdTrendingUp,
          label: 'RTP',
          active: location.pathname === '/reports/rtpsummary',
          href: '/reports/rtpsummary'
        },
        {
          icon: MdPeopleOutline,
          label: 'Users',
          active: location.pathname === '/reports/usersummary',
          href: '/reports/usersummary'
        }
      ]
    }
  ])

  return (
    <>
      <AppNavBar
        title={title}
        mainItems={mainItems}
        onMainItemSelect={item => {
          if (item.href && item.href !== location.pathname) {
            history.push(item.href)
          } else {
            setMainItems(prev => setItemActive(prev, item))
          }
        }}
        username={state.user.userName}
        usernameSubtitle=''
        userItems={[
          { icon: CheckIndeterminate, label: 'Theme' },
          { icon: Overflow, label: 'Logout' }
        ]}
        onUserItemSelect={item => {
          if (item.label === 'Theme') {
            darkMode.toggle()
          } else if (item.label === 'Logout') {
            dispatch({ type: ACTIONS.USER_LOGOUT })
            history.push('/login')
          }
        }}
      />
      <div style={{ borderTop: '1px solid #EEEEEE', padding: '1em' }}>
        {children}
      </div>
      <Footer darkMode={darkMode.value} />
    </>
  )
}
