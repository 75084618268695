import { Card, StyledBody } from 'baseui/card'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { useAggregationStates } from '../data/AggregationStates'
import { numberFormat } from '../utils/Number'
import { Divider } from './Divider'

const AggregationState = ({ title, state }) => {
  return (
    <>
      <div>{title}</div>
      <div>Update: <b>{new Date(state.date).toLocaleString()}</b></div>
      <div>Total: <b>{numberFormat(state.total)}</b></div>
      <Divider />
    </>
  )
}

export function AggregationStates () {
  const { state: { user } } = useContext(AppContext)
  const dataLoader = useAggregationStates(user.token)
  let content
  if (dataLoader.isError) {
    content = <div>Error</div>
  } else if (dataLoader.isLoading) {
    content = <div>Loading</div>
  } else {
    content = (
      <>
        <AggregationState title='MONTHLY REPORT' state={dataLoader.data.monthly} />
        <AggregationState title='DAILY REPORT' state={dataLoader.data.daily} />
        <AggregationState title='USER REPORT' state={dataLoader.data.user} />
        <AggregationState title='RTP REPORT' state={dataLoader.data.rtp} />
      </>
    )
  }
  return (
    <Card>
      <StyledBody>
        {content}
      </StyledBody>
    </Card>
  )
}
