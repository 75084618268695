import * as React from 'react'
import { SESSION_STORAGE_USER_KEY, ACTIONS } from '../utils/Constants'

export const AppContext = React.createContext()

function getUserFromSession () {
  const user = window.localStorage.getItem(SESSION_STORAGE_USER_KEY)
  if (user) {
    try {
      return JSON.parse(user)
    } catch (error) {
      console.warn('Invalid json object')
      window.localStorage.clear()
    }
  }
}

const initialState = {
  user: getUserFromSession()
}

function reducer (state, action) {
  switch (action.type) {
    case ACTIONS.USER_LOGIN:
      window.localStorage.setItem(SESSION_STORAGE_USER_KEY, JSON.stringify(action.user))
      return {
        ...state,
        user: action.user
      }
    case ACTIONS.USER_LOGOUT:
      window.localStorage.clear()
      return { ...state, user: undefined }
    default:
      return state
  }
}

export function AppContextProvider ({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
