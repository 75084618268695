import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { Card, StyledBody } from 'baseui/card'

export function Clock ({ timeZone }) {
  const [currentTime, setCurrentTime] = useState(DateTime.now().setZone(timeZone))
  useEffect(() => {
    const tick = setInterval(() => {
      const now = DateTime.now().setZone(timeZone)
      setCurrentTime(now)
    }, 1000)
    return () => clearInterval(tick)
  })

  return (
    <Card>
      <StyledBody>
        <b style={{ fontSize: '1.3rem' }}>{currentTime.toFormat('tt')}</b>
        <div style={{ fontSize: '0.8rem' }}>{currentTime.toFormat('DDDD')} ({currentTime.offsetNameShort})</div>
      </StyledBody>
    </Card>
  )
}
