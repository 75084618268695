import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { SESSION_STORAGE_USER_KEY } from './Constants'

function PrivateRoute ({ children, ...rest }) {
  const render = ({ location }) => {
    if (window.localStorage.getItem(SESSION_STORAGE_USER_KEY)) {
      return children
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      )
    }
  }
  return <Route {...rest} render={render} />
}

export default PrivateRoute
