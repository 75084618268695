import { Grid, Cell, BEHAVIOR } from 'baseui/layout-grid'
import { AppLayout } from '../../components/AppLayout'
import { MonthlySummaryTable } from '../../components/reports/MonthlySummaryTable'
import { SearchPanel } from '../../components/SearchPanel'
import { Pagination } from '../../components/Pagination'
import { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { useMonthlySummaries } from '../../data/DailySummary'

const PageContent = () => {
  const { state: { user } } = useContext(AppContext)
  const [queryArgs, setQueryArgs] = useState({
    previousCursors: [],
    cursor: undefined,
    merchant: undefined,
    game: undefined,
    startDate: undefined,
    endDate: undefined
  })
  const summaries = useMonthlySummaries(user.token, queryArgs)
  const onSearchArgsChanged = args => {
    setQueryArgs({
      ...queryArgs,
      merchant: args.merchant,
      game: args.game,
      startDate: args.startDate,
      endDate: args.endDate,
      previousCursors: [],
      cursor: undefined
    })
  }
  const clickPrevHandler = _ => {
    const cursor = queryArgs.previousCursors.pop()
    setQueryArgs({
      ...queryArgs,
      previousCursors: queryArgs.previousCursors,
      cursor: cursor
    })
  }
  const clickNextHandler = _ => {
    queryArgs.previousCursors.push(queryArgs.cursor)
    setQueryArgs({
      ...queryArgs,
      cursor: summaries.data.cursor
    })
  }
  return (
    <Grid behavior={BEHAVIOR.fluid} gridMargins={0} gridGutters={10}>
      <Cell span={2}>
        <SearchPanel token={user.token} isLoading={summaries.isLoading} onSearchArgsChanged={onSearchArgsChanged} />
      </Cell>
      <Cell span={10}>
        <MonthlySummaryTable loader={summaries} />
        <Pagination
          isLoading={summaries.isLoading}
          noPrevData={queryArgs.previousCursors.length === 0}
          noMoreData={summaries.data?.noMoreData}
          onClickPrev={clickPrevHandler}
          onClickNext={clickNextHandler}
        />
      </Cell>
    </Grid>
  )
}

export default function MonthlySummaryPage () {
  return (
    <AppLayout title='Monthly Summary Report'>
      <PageContent />
    </AppLayout>
  )
}
