import useSWR from 'swr'
import { Grid, Cell, BEHAVIOR } from 'baseui/layout-grid'
import * as Client from '../../data/Client'
import { AppLayout } from '../../components/AppLayout'
import { RTPSummaryTable } from '../../components/reports/RTPSummaryTable'
import { SearchPanel } from '../../components/SearchPanel'
import { Pagination } from '../../components/Pagination'
import { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext'

const fetcher = async (url, token, game, cursor, startDate, endDate) => {
  if (token) {
    const body = {
      game,
      cursor,
      startDate,
      endDate,
      size: 20
    }
    const client = Client.create(token)
    const rsp = await client.post(url, body)
    return rsp.data
  } else {
    throw new Error('token is undefiend')
  }
}

function useRTPSummaries (token, queryArgs) {
  const { data, error } = useSWR([
    'admin/reports/rtpsummary',
    token,
    queryArgs.game,
    queryArgs.cursor,
    queryArgs.startDate,
    queryArgs.endDate], fetcher)
  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}

const PageContent = () => {
  const { state: { user } } = useContext(AppContext)
  const [queryArgs, setQueryArgs] = useState({
    previousCursors: [],
    game: undefined,
    cursor: undefined,
    startDate: undefined,
    endDate: undefined
  })
  const summaries = useRTPSummaries(user.token, queryArgs)
  const onSearchArgsChanged = args => {
    setQueryArgs({
      ...queryArgs,
      game: args.game,
      startDate: args.startDate,
      endDate: args.endDate,
      previousCursors: [],
      cursor: undefined
    })
  }
  const clickPrevHandler = _ => {
    const cursor = queryArgs.previousCursors.pop()
    setQueryArgs({
      ...queryArgs,
      previousCursors: queryArgs.previousCursors,
      cursor: cursor
    })
  }
  const clickNextHandler = _ => {
    queryArgs.previousCursors.push(queryArgs.cursor)
    setQueryArgs({
      ...queryArgs,
      cursor: summaries.data.cursor
    })
  }
  return (
    <Grid behavior={BEHAVIOR.fluid} gridMargins={0} gridGutters={10}>
      <Cell span={2}>
        <SearchPanel token={user.token} isLoading={summaries.isLoading} onSearchArgsChanged={onSearchArgsChanged} />
      </Cell>
      <Cell span={10}>
        <RTPSummaryTable loader={summaries} clickNextHandler={clickNextHandler} />
        <Pagination
          isLoading={summaries.isLoading}
          noPrevData={queryArgs.previousCursors.length === 0}
          noMoreData={summaries.data?.noMoreData}
          onClickPrev={clickPrevHandler}
          onClickNext={clickNextHandler}
        />
      </Cell>
    </Grid>
  )
}

export default function RTPSummaryPage () {
  return (
    <AppLayout title='RTP Summary Report'>
      <PageContent />
    </AppLayout>
  )
}
