import useSWR from 'swr'
import { useLocation } from 'react-router-dom'
import { Grid, Cell, BEHAVIOR } from 'baseui/layout-grid'
import * as Client from '../data/Client'
import { AppLayout } from '../components/AppLayout'
import { SearchPanel } from '../components/SearchPanel'
import { GameTransactionsTable } from '../components/GameTransaction/Table'
import { useContext, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { Pagination } from '../components/Pagination'

const fetcher = async (url, token, cursor, user, game, startDate, endDate) => {
  if (token) {
    const body = {
      game,
      cursor,
      user,
      startDate,
      endDate,
      size: 20
    }
    const client = Client.create(token)
    const rsp = await client.post(url, body)
    return rsp.data
  } else {
    throw new Error('token is undefiend')
  }
}

function useGameTransactions (token, queryArgs) {
  const { data, error } = useSWR([
    'admin/slots/transactions',
    token,
    queryArgs.cursor,
    queryArgs.user,
    queryArgs.game,
    queryArgs.startDate,
    queryArgs.endDate], fetcher)
  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}

const PageContent = () => {
  const { state: { user } } = useContext(AppContext)
  const location = useLocation()
  const qs = new URLSearchParams(location.search)
  const [queryArgs, setQueryArgs] = useState({
    previousCursors: [],
    cursor: undefined,
    merchant: undefined,
    user: qs.get('u'),
    startDate: undefined,
    endDate: undefined,
    game: undefined
  })
  const transactions = useGameTransactions(user.token, queryArgs)
  const onSearchArgsChanged = args => {
    setQueryArgs({
      ...queryArgs,
      merchant: args.merchant,
      game: args.game,
      user: args.user,
      startDate: args.startDate,
      endDate: args.endDate,
      previousCursors: [],
      cursor: undefined
    })
  }
  const clickPrevHandler = _ => {
    const cursor = queryArgs.previousCursors.pop()
    setQueryArgs({
      ...queryArgs,
      previousCursors: queryArgs.previousCursors,
      cursor: cursor
    })
  }
  const clickNextHandler = _ => {
    queryArgs.previousCursors.push(queryArgs.cursor)
    setQueryArgs({
      ...queryArgs,
      previousCursors: queryArgs.previousCursors,
      cursor: transactions.data.cursor
    })
  }
  return (
    <Grid behavior={BEHAVIOR.fluid} gridMargins={0} gridGutters={10}>
      <Cell span={2}>
        <SearchPanel
          token={user.token}
          user={queryArgs.user}
          isLoading={transactions.isLoading}
          onSearchArgsChanged={onSearchArgsChanged}
        />
      </Cell>
      <Cell span={10}>
        <GameTransactionsTable loader={transactions} />
        <Pagination
          isLoading={transactions.isLoading}
          noPrevData={queryArgs.previousCursors.length === 0}
          noMoreData={transactions.data?.noMoreData}
          onClickPrev={clickPrevHandler}
          onClickNext={clickNextHandler}
        />
      </Cell>
    </Grid>
  )
}

export default function GameTransactionsPage () {
  return (
    <AppLayout title='Game Transactions'>
      <PageContent />
    </AppLayout>
  )
}
