import { Decimal } from 'decimal.js-light'
import { Wheel as FruitManiaWheel } from './FruitMania/Wheel'
import { Wheel as CandyBurstWheel } from './CandyBurst/Wheel'
import { Wins as CandyBurstWins } from './CandyBurst/Wins'
import { Wins as FruitManiaWins } from './FruitMania/Wins'
import { currencyFormat } from '../../utils/Number'
import { Card, StyledBody } from 'baseui/card'
import { StyledLink as Link } from 'baseui/link'
import { useStyletron } from 'baseui'
import { BEHAVIOR, Cell, Grid } from 'baseui/layout-grid'

const Entry = ({ label, value }) => <div>{label}: <b>{value}</b></div>

function renderWheel (transaction) {
  if (transaction.game === 'candy-burst' || transaction.game === 'fanatic-ape') {
    return <CandyBurstWheel transaction={transaction} />
  }
  return <FruitManiaWheel game={transaction.game} wheel={transaction.state} />
}

function renderWins (transaction, lineBet) {
  if (transaction.game === 'candy-burst' || transaction.game === 'fanatic-ape') {
    return <CandyBurstWins transaction={transaction} />
  }
  return <FruitManiaWins transaction={transaction} lineBet={lineBet} />
}

function stateName (transaction) {
  if (transaction.game === 'candy-burst' || transaction.game === 'fanatic-ape') {
    const cascading = transaction.state.cascading
    let name = 'COLLAPSED'
    if (cascading.restore) {
      name = 'MAIN GAME RESTORED'
    } else if (cascading.beginning && cascading.end) {
      name = 'COMPLETED'
    } else if (cascading.beginning) {
      name = 'CASCADING STARTED'
    } else if (cascading.end) {
      name = 'CASCADING STOPPED'
    }
    return name
  } else {
    return 'MAIN GAME'
  }
}

export function GameTransation ({ transaction }) {
  const [css, theme] = useStyletron()
  const date = new Date(parseFloat(transaction.date) * 1000)
  const winlose = currencyFormat(transaction.bet - transaction.winAmount)
  const color = winlose > 0 ? theme.colors.positive : theme.colors.negative
  const lineBet = new Decimal(transaction.bet).dividedBy(new Decimal(transaction.lines))
  return (
    <Card>
      <StyledBody>
        <Entry label='Game' value={transaction.game} />
        <Entry label='Merchant' value={transaction.merchant} />
        <div>User: <b>{transaction.user}</b> - <Link href={`/slots/transactions?u=${transaction.user}`}>Transacions</Link></div>
        <Entry label='Lines' value={transaction.lines} />
        <div>Line Bet: <b>{lineBet.toNumber()}</b>, Total Bet: <b>{transaction.bet}</b>, Win: <b>{transaction.winAmount}</b></div>
        <div>Win/Lose: <b className={css({ color: color, fontSize: '1.5rem' })}>{winlose}</b></div>
        <div>Payout: <b>{transaction.payout}</b> ({transaction.payoutElapsed.toFixed(2)} ms)</div>
        <Entry label='End Balance' value={currencyFormat(transaction.balance)} />
        <Entry label='Date' value={date.toLocaleString()} />
        <Entry label='State' value={stateName(transaction)} />
      </StyledBody>
      <StyledBody>
        <Grid behavior={BEHAVIOR.fluid} gridGaps={0} gridGutters={0} gridMargins={0}>
          <Cell span={[12, 12, 8]}>
            {renderWheel(transaction)}
          </Cell>
          <Cell span={[12, 12, 4]}>
            {renderWins(transaction, lineBet)}
          </Cell>
        </Grid>
      </StyledBody>
    </Card>
  )
}
