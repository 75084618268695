import { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import validator from 'validate.js'
import { Card, StyledBody } from 'baseui/card'
import { DisplayXSmall } from 'baseui/typography'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Button } from 'baseui/button'
import { Notification, KIND } from 'baseui/notification'
import * as Client from '../data/Client'
import { AppContext } from '../context/AppContext'
import { ACTIONS } from '../utils/Constants'

const overrides = {
  Root: {
    style: {
      maxWidth: '420px',
      width: '95vw',
      margin: '10rem auto'
    }
  },
  Body: {
    style: {
      padding: '1rem'
    }
  }
}

const constrains = {
  username: {
    presence: true,
    length: { minimum: 3 }
  },
  password: {
    presence: true,
    length: { minimum: 8 }
  }
}

const LoginFailed = ({ error }) => {
  let msg = error
  if (error === 'InvalidCredentials') {
    msg = 'Invalid credentials'
  } else if (error === 'AccountSuspended') {
    msg = 'Account is suspended'
  } else if (error.kind) {
    msg = error.value
  }
  return (
    <Notification kind={KIND.negative}>
      {msg}
    </Notification>
  )
}

export default function Login () {
  const { dispatch } = useContext(AppContext)
  const history = useHistory()
  const [state, setState] = useState({ username: '', password: '' })

  const onInputChange = inputKey => e => {
    const newState = {
      ...state,
      [inputKey]: e.currentTarget.value
    }
    setState({
      ...newState,
      validationErrors: validator(newState, constrains)
    })
  }

  const onClickLoginHandler = useCallback(async () => {
    const validations = validator(state, constrains)
    if (validations) {
      setState({ ...state, validationErrors: validations, error: undefined })
    } else {
      setState({ ...state, isLoading: true, error: undefined })
      const rsp = await Client.login(state.username, state.password)
      if (!rsp.error) {
        dispatch({ type: ACTIONS.USER_LOGIN, user: rsp })
        history.push('/')
      } else {
        setState({ ...state, isLoading: false, error: rsp.error })
      }
    }
  }, [state, setState, dispatch, history])

  return (
    <Card overrides={overrides}>
      <StyledBody>
        <DisplayXSmall>Back Office</DisplayXSmall>
      </StyledBody>
      <StyledBody>
        <FormControl disabled={state.isLoading} label='Username' error={state.validationErrors?.username}>
          <Input id='username' error={state.validationErrors?.username} onChange={onInputChange('username')} value={state.username} />
        </FormControl>
        <FormControl disabled={state.isLoading} label='Password' error={state.validationErrors?.password}>
          <Input id='password' error={state.validationErrors?.password} clearable type='password' onChange={onInputChange('password')} value={state.password} />
        </FormControl>
        <Button onClick={onClickLoginHandler} isLoading={state.isLoading}>Login</Button>
        {state.error && <LoginFailed error={state.error} />}
      </StyledBody>
    </Card>
  )
}
