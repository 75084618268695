import { AppLayout } from '../components/AppLayout'
import { Grid, Cell } from 'baseui/layout-grid'
import { Card, StyledBody } from 'baseui/card'
import { TopWinners } from '../components/dashboard/TopWinners'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { useTopWinners } from '../data/UserSummary'
import { WinLoseCharts } from '../components/dashboard/WinLoseCharts'
import { TodaySummary } from '../components/dashboard/TodaySummary'
import { AggregationStates } from '../components/AggregationStates'

const PageContent = () => {
  const { state: { user } } = useContext(AppContext)
  const topWinners = useTopWinners(user.token)

  return (
    <>
      <TodaySummary token={user.token} />
      <Grid gridGaps={12} gridGutters={5}>
        <Cell span={[12, 12, 6]}>
          <WinLoseCharts token={user.token} currency='KSH' />
        </Cell>
        <Cell span={[12, 12, 3]}>
          <AggregationStates token={user.token} />
        </Cell>
        <Cell span={[12, 12, 3]}>
          <Card>
            <StyledBody>
              Top Winners
            </StyledBody>
            <StyledBody>
              <TopWinners loader={topWinners} />
            </StyledBody>
          </Card>
        </Cell>
      </Grid>
    </>
  )
}

export default function Dashboard () {
  return (
    <AppLayout title='Home'>
      <PageContent />
    </AppLayout>
  )
}
