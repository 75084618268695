import { Decimal } from 'decimal.js-light'
import './styles.css'

const Line1 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td className='active' />
        <td className='active' />
        <td className='active' />
      </tr>
      <tr>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </table>
)

const Line2 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td className='active' />
        <td className='active' />
        <td className='active' />
      </tr>
      <tr>
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </table>
)

const Line3 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td />
        <td />
        <td />
      </tr>
      <tr>
        <td className='active' />
        <td className='active' />
        <td className='active' />
      </tr>
    </tbody>
  </table>
)

const Line4 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td className='active' />
        <td />
        <td />
      </tr>
      <tr>
        <td className='active' />
        <td />
        <td />
      </tr>
      <tr>
        <td className='active' />
        <td />
        <td />
      </tr>
    </tbody>
  </table>
)

const Line5 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td />
        <td className='active' />
        <td />
      </tr>
      <tr>
        <td />
        <td className='active' />
        <td />
      </tr>
      <tr>
        <td />
        <td className='active' />
        <td />
      </tr>
    </tbody>
  </table>
)

const Line6 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td />
        <td />
        <td className='active' />
      </tr>
      <tr>
        <td />
        <td />
        <td className='active' />
      </tr>
      <tr>
        <td />
        <td />
        <td className='active' />
      </tr>
    </tbody>
  </table>
)

const Line7 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td className='active' />
        <td />
        <td />
      </tr>
      <tr>
        <td />
        <td className='active' />
        <td />
      </tr>
      <tr>
        <td />
        <td />
        <td className='active' />
      </tr>
    </tbody>
  </table>
)

const Line8 = () => (
  <table className='payline'>
    <tbody>
      <tr>
        <td />
        <td />
        <td className='active' />
      </tr>
      <tr>
        <td />
        <td className='active' />
        <td />
      </tr>
      <tr>
        <td className='active' />
        <td />
        <td />
      </tr>
    </tbody>
  </table>
)

const paylines = [
  Line1,
  Line2,
  Line3,
  Line4,
  Line5,
  Line6,
  Line7,
  Line8
]

const getLine = (x) => {
  const i = x.line.number
  if (i >= 0 && i < paylines.length) {
    return paylines[i]()
  }
  return <div key={i}>Invalid number {i}</div>
}

export function Wins ({ transaction, lineBet }) {
  return (
    <div>
      {transaction.win.map(x => {
        const line = getLine(x)
        return (
          <div key={x.line.number} className='winning-line'>
            {line}
            <div>{lineBet.toNumber()} x <b>{x.multiplier}</b> = {new Decimal(x.multiplier).times(lineBet).toNumber()}</div>
          </div>
        )
      })}
    </div>
  )
}
