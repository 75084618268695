import useSWR from 'swr'
import * as Client from './Client'

const fetcher = async (url, token, merchant, game, cursor, startDate, endDate, size) => {
  if (token) {
    const body = {
      merchant,
      game,
      cursor,
      startDate,
      endDate,
      size: size || 31
    }
    const client = Client.create(token)
    const rsp = await client.post(url, body)
    return rsp.data
  } else {
    throw new Error('token is undefiend')
  }
}

export function useDailySummaries (token, queryArgs) {
  const x = useSWR([
    'admin/reports/dailysummary',
    token,
    queryArgs.merchant,
    queryArgs.game,
    queryArgs.cursor,
    queryArgs.startDate,
    queryArgs.endDate,
    queryArgs.size], fetcher)
  return {
    data: x.data,
    isLoading: !x.error && !x.data,
    isError: x.error
  }
}

export function useMonthlySummaries (token, queryArgs) {
  const x = useSWR([
    'admin/reports/monthlysummary',
    token,
    queryArgs.merchant,
    queryArgs.game,
    queryArgs.cursor,
    queryArgs.startDate,
    queryArgs.endDate,
    queryArgs.size], fetcher)
  return {
    data: x.data,
    isLoading: !x.error && !x.data,
    isError: x.error
  }
}
