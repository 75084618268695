import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Provider as StyletronProvider } from 'styletron-react'
import useDarkMode from 'use-dark-mode'
import { LightTheme, DarkTheme, ThemeProvider } from 'baseui'
import { Client as Styletron } from 'styletron-engine-atomic'
import UserSummaryPage from './pages/reports/UserSummary'
import DailySummaryPage from './pages/reports/DailySummary'
import MonthlySummary from './pages/reports/MonthlySummary'
import RTPSummaryPage from './pages/reports/RTPSummary'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import GameTransactions from './pages/GameTransactions'
import GameTransactionDetail from './pages/GameTransactionDetail'
import PrivateRoute from './utils/PrivateRoute'
import { AppContextProvider } from './context/AppContext'

const engine = new Styletron()

export default function App () {
  const darkMode = useDarkMode(false)
  const theme = darkMode.value ? DarkTheme : LightTheme
  return (
    <StyletronProvider value={engine}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path='/login'>
                <Login />
              </Route>
              <PrivateRoute exact path='/'>
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute exact path='/slots/transactions'>
                <GameTransactions />
              </PrivateRoute>
              <PrivateRoute exact path='/slots/transaction/detail'>
                <GameTransactionDetail />
              </PrivateRoute>
              <PrivateRoute exact path='/reports/usersummary'>
                <UserSummaryPage />
              </PrivateRoute>
              <PrivateRoute exact path='/reports/dailysummary'>
                <DailySummaryPage />
              </PrivateRoute>
              <PrivateRoute exact path='/reports/monthlysummary'>
                <MonthlySummary />
              </PrivateRoute>
              <PrivateRoute exact path='/reports/rtpsummary'>
                <RTPSummaryPage />
              </PrivateRoute>
            </Switch>
          </Router>
        </ThemeProvider>
      </AppContextProvider>
    </StyletronProvider>
  )
}
