import { ButtonGroup } from 'baseui/button-group'
import { Button } from 'baseui/button'
import ArrowLeft from 'baseui/icon/arrow-left'
import ArrowRight from 'baseui/icon/arrow-right'

export function Pagination ({ isLoading, noPrevData, noMoreData, onClickPrev, onClickNext }) {
  return (
    <ButtonGroup>
      <Button isLoading={isLoading} disabled={noPrevData} onClick={onClickPrev}>
        <ArrowLeft />Prev
      </Button>
      <Button isLoading={isLoading} disabled={noMoreData} onClick={onClickNext}>
        Next<ArrowRight />
      </Button>
    </ButtonGroup>
  )
}
