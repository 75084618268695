import './styles.css'

const symbolsMappings = {
  'fruit-mania': {
    0: 'j.png',
    1: 'q.png',
    2: 'k.png',
    3: 'a.png',
    4: 'cherry.png',
    5: 'bell.png',
    6: 'diamond.png',
    7: '7.png',
    8: 'bar.png'
  },
  'euro-cup': {
    0: 'j.png',
    1: 'q.png',
    2: 'k.png',
    3: 'a.png',
    4: 's1.png',
    5: 's2.png',
    6: 's3.png',
    7: 's4.png',
    8: 's5.png'
  }
}

export function Wheel ({ wheel, game }) {
  const symbol = x => {
    return (
      <img
        src={`/${game}/${symbolsMappings[game][x]}`}
        alt='symbole'
        width={85}
      />
    )
  }
  return (
    <table className='wheel'>
      <tbody>
        <tr>
          <td>{symbol(wheel[0])}</td>
          <td>{symbol(wheel[1])}</td>
          <td>{symbol(wheel[2])}</td>
        </tr>
        <tr>
          <td>{symbol(wheel[3])}</td>
          <td>{symbol(wheel[4])}</td>
          <td>{symbol(wheel[5])}</td>
        </tr>
        <tr>
          <td>{symbol(wheel[6])}</td>
          <td>{symbol(wheel[7])}</td>
          <td>{symbol(wheel[8])}</td>
        </tr>
      </tbody>
    </table>
  )
}
