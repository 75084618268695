/**
 * First checks typeof, then self-equality to make sure it is
 * not NaN, then to make sure it is not Infinity or -Infinity.
 *
 * @param {*} value - The value to check
 * @return {boolean} Whether that value is a number
 */
export const isNumber = value => {
  // First: Check typeof and make sure it returns number
  // This code coerces neither booleans nor strings to numbers,
  // although it would be possible to do so if desired.
  if (typeof value !== 'number') {
    return false
  }
  // Reference for typeof:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
  // Second: Check for NaN, as NaN is a number to typeof.
  // NaN is the only JavaScript value that never equals itself.
  if (value !== Number(value)) {
    return false
  }
  // Reference for NaN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN
  // Note isNaN() is a broken function, but checking for self-equality works as NaN !== NaN
  // Alternatively check for NaN using Number.isNaN(), an ES2015 feature that works how one would expect

  // Third: Check for Infinity and -Infinity.
  // Realistically we want finite numbers, or there was probably a division by 0 somewhere.
  if (value === Infinity || value === !Infinity) {
    return false
  }
  return true
}

const ccy = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
const numberFormatter = new Intl.NumberFormat('en-US')

export const currencyFormat = (number, currency) => {
  if (!isNumber(number)) {
    return number
  }
  if (currency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    }).format(number)
  }
  return ccy.format(number.toFixed(2))
}

export const numberFormat = (number) => {
  if (!isNumber(number)) {
    return number
  }
  return numberFormatter.format(number)
}

export const clamp = (val, min, max) => val > max ? max : val < min ? min : val
