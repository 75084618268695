import { useStyletron } from 'baseui'
import { StyledLink as Link } from 'baseui/link'
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic'
import { Decimal } from 'decimal.js-light'
import { currencyFormat } from '../../utils/Number'
import { formatDateTime } from '../../utils/Utils'

const overrides = {
  Root: {
    style: () => ({
      marginTop: '1em'
    })
  },
  TableBodyRow: {
    style: ({ $theme, $rowIndex }) => ({
      backgroundColor:
        $rowIndex % 2
          ? $theme.colors.backgroundPrimary
          : $theme.colors.backgroundSecondary,
      ':hover': {
        backgroundColor: $theme.colors.backgroundTertiary
      }
    })
  }
  // TableBodyCell: {
  //   style: (x) => {
  //     if (x.$colIndex !== 0) {
  //       return { width: '160px' }
  //     } else {
  //       return { width: '150px' }
  //     }
  //   }
  // }
}

export function GameTransactionsTable ({ loader }) {
  const [css, theme] = useStyletron()
  const data = (loader.data?.items || []).map(x => {
    const bet = new Decimal(x.bet)
    const win = new Decimal(x.winAmount)
    const amount = bet.sub(win).toNumber()
    return {
      ...x,
      netWin: amount
    }
  })
  return (
    <TableBuilder
      overrides={overrides}
      isLoading={loader.isLoading}
      data={data}
      emptyMessage='No data'
    >
      <TableBuilderColumn header='Date'>
        {x => {
          const date = new Date(parseFloat(x.date) * 1000)
          return formatDateTime(date)
        }}
      </TableBuilderColumn>
      <TableBuilderColumn header='Game'>
        {x => x.game}
      </TableBuilderColumn>
      <TableBuilderColumn header='CCY'>
        {x => x.currency}
      </TableBuilderColumn>
      <TableBuilderColumn header='User'>
        {x => x.user}
      </TableBuilderColumn>
      <TableBuilderColumn header='Payout'>
        {x => x.payout}
      </TableBuilderColumn>
      <TableBuilderColumn header='Bet'>
        {x => currencyFormat(x.bet)}
      </TableBuilderColumn>
      <TableBuilderColumn header='Win'>
        {x => currencyFormat(x.winAmount)}
      </TableBuilderColumn>
      <TableBuilderColumn header='Net Win'>
        {x => {
          let color
          if (x.netWin < 0) color = theme.colors.negative
          return (
            <div className={css({
              fontWeight: '700',
              textAlign: 'right',
              color: color
            })}
            >
              {currencyFormat(x.netWin)}
            </div>
          )
        }}
      </TableBuilderColumn>
      <TableBuilderColumn header='End Balance'>
        {x => currencyFormat(x.balance)}
      </TableBuilderColumn>
      <TableBuilderColumn header='...'>
        {x => {
          return (
            <>
              <Link href={`/reports/usersummary?u=${x.user}`}>User Summary</Link>{' | '}
              <Link href={`/slots/transaction/detail?id=${x.id}&u=${x.user}&g=${x.game}`}>Detail</Link>
            </>
          )
        }}
      </TableBuilderColumn>
    </TableBuilder>
  )
}
