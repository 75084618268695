import { StyledLink as Link } from 'baseui/link'
import Decimal from 'decimal.js-light'
import Loading from '../Loading'
import { numberFormat, currencyFormat } from '../../utils/Number'
import { useStyletron } from 'baseui'

export function TopWinners ({ loader }) {
  const [css] = useStyletron()
  const data = loader.data?.items || []
  if (loader.isLoading) {
    return <Loading label='Data is loading' />
  }
  if (!loader.isLoading && data.length === 0) {
    return <div>No data for today</div>
  }
  return (
    <ul className={css({
      listStyle: 'none',
      margin: '0',
      padding: '0'
    })}
    >
      {data.map((x, i) => {
        const rtp = new Decimal(x.totalWin).div(new Decimal(x.totalBet)).times(100).toNumber()
        return (
          <li
            key={`${x.userId}-${i}`} style={{
              borderBottom: '1px solid #ddd',
              marginBottom: '5px'
            }}
          >
            <div><Link href={`/slots/transactions?u=${x.userId}`}>{x.userId}</Link> ({numberFormat(x.totalTransactions)})</div>
            <div>BET: {currencyFormat(x.totalBet)} / WIN: {currencyFormat(x.totalWin)}</div>
            <div>RTP: <b>{currencyFormat(rtp.toFixed(2)) + ' %'}</b></div>
          </li>
        )
      })}
    </ul>
  )
}
