import useSWR from 'swr'
import * as Client from './Client'

export const fetcher = async (url, token, cursor, merchant, game, user, startDate, endDate) => {
  if (token) {
    const body = {
      cursor,
      merchant,
      game,
      user,
      startDate,
      endDate,
      size: 20
    }
    const client = Client.create(token)
    const rsp = await client.post(url, body)
    return rsp.data
  } else {
    throw new Error('token is undefiend')
  }
}

export function useUserSummaries (token, queryArgs) {
  const { data, error } = useSWR([
    'admin/reports/usersummary',
    token,
    queryArgs.cursor,
    queryArgs.merchant,
    queryArgs.game,
    queryArgs.user,
    queryArgs.startDate,
    queryArgs.endDate], fetcher)
  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useTopWinners (token) {
  const { data, error } = useSWR(['admin/dashboard/topwinners', token], fetcher)
  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}
