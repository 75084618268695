import { getDisappearCellsAsMatrix } from '../Utils'
import './styles.css'

const symbolsMappings = {
  'candy-burst': {
    0: 's0.png',
    1: 's1.png',
    2: 's2.png',
    3: 's3.png',
    4: 's4.png',
    5: 's5.png',
    6: 's6.png',
    7: 's7.png',
    8: 's8.png',
    9: 's9.png',
    10: 's10.png',
    11: 's11.png',
    12: 's12.png',
    13: 's13.png',
    14: 's14.png',
    15: 's15.png',
    16: 's16.png',
    17: 's17.png'
  },
  'fanatic-ape': {
    0: 'Symbol_02_Normal.png',
    1: 'Symbol_03_Normal.png',
    2: 'Symbol_04_Normal.png',
    3: 'Symbol_05_Normal.png',
    4: 'Symbol_06_Normal.png',
    5: 'Symbol_08_Normal.png',
    6: 'Symbol_09_Normal.png',
    7: 'Symbol_10_Normal.png',
    8: 'Symbol_12_Normal.png',
    9: 'Symbol_11_Normal.png',
    10: 'Symbol_22_Normal.png',
    11: 'Symbol_23_Normal.png',
    12: 'Symbol_24_Normal.png',
    13: 'Symbol_25_Normal.png',
    14: 'Symbol_28_Normal.png',
    15: 'Symbol_29_Normal.png',
    16: 'Symbol_30_Normal.png',
    17: 'Symbol_26_Normal.png'
  }
}

const Symbol = ({ game, code }) => {
  return (
    <img
      src={`/${game}/${symbolsMappings[game][code]}`}
      alt='symbole'
      width={50}
    />
  )
}

function renderWheel ({ transaction, isAfter }) {
  const wheel = isAfter && transaction.state.after ? transaction.state.after : transaction.state.wheel
  const game = transaction.game
  const disappeared = getDisappearCellsAsMatrix([...transaction.win, transaction.state.trigger], 5, 3)

  const Spawned = ({ spawned }) => {
    if (isAfter || !spawned || spawned.flat().length === 0) {
      return <></>
    }
    const reels = spawned.map((reel, i) => {
      if (reel.length > 0) {
        const r = reel.map((s, j) => <li key={`spawned-${i}-${j}`}><Symbol game={game} code={s} /></li>)
        return <ul key={`spawned-${i}`}>{r}</ul>
      } else {
        return <ul key={`spawned-${i}`}><li /></ul>
      }
    })
    return <div className='wheel-spawned'>{reels}</div>
  }

  const Row = ({ row }) => {
    const tds = []
    for (let col = 0; col < 5; col++) {
      const cls = disappeared[col][row] === 1 && !isAfter ? 'active' : ''
      tds.push(<td className={cls} key={`cell-${col}-${row}`}><Symbol game={game} code={wheel[col][row]} /></td>)
    }
    return <tr>{tds}</tr>
  }

  const Rows = () => {
    const trs = []
    for (let row = 0; row < 3; row++) {
      trs.push(<Row key={`row-${row}`} row={row} />)
    }
    return trs
  }

  return (
    <div>
      <Spawned spawned={transaction.state.spawned} />
      <table className='wheel'>
        <tbody>
          {Rows()}
        </tbody>
      </table>
    </div>
  )
}

export function Wheel ({ transaction }) {
  return (
    <div>
      {renderWheel({ transaction, isAfter: false })}
      {transaction.win.length > 0 && renderWheel({ transaction, isAfter: true })}
    </div>
  )
}
