import { useStyletron } from 'baseui'
import { Decimal } from 'decimal.js-light'
import { currencyFormat } from '../../utils/Number'
import { convertToUSD } from '../../utils/Utils'

function sumBy (data, by) {
  return data.reduce((total, x) => new Decimal(by(x)).add(total), new Decimal(0))
}

function Summary ({ currency, data }) {
  const [css, theme] = useStyletron()
  const totalBet = sumBy(data, x => x.totalBet)
  const totalWin = sumBy(data, x => x.totalWin)
  const netWin = totalBet.sub(totalWin)
  const RTP = totalWin.div(totalBet).times(100).toNumber().toFixed(2)
  const color = netWin.toNumber() <= 0 ? theme.colors.negative : theme.colors.positive
  return (
    <div className={css({ margin: '1rem 0' })}>
      <b>{currency}</b> |
      Total Bet: <b>{currencyFormat(totalBet.toNumber())}</b> /
      Total Win: <b>{currencyFormat(totalWin.toNumber())}</b> /
      Net Win: <b style={{ color: color }}>{currencyFormat(netWin.toNumber())}</b> (<b>USD{convertToUSD(currency, netWin)}</b>) /
      RTP: <b style={{ color: color }}>{RTP}%</b>
    </div>
  )
}

export function TotalSummary ({ data }) {
  const currencies = {}
  for (let i = 0; i < data.length; i++) {
    const x = data[i]
    if (currencies[x.currency]) {
      currencies[x.currency].push(x)
    } else {
      currencies[x.currency] = [x]
    }
  }
  const summaries = []
  for (const k in currencies) {
    summaries.push(<Summary key={k} currency={k} data={currencies[k]} />)
  }
  return summaries
}
