import useSWR from 'swr'
import * as Client from './Client'

export const fetcher = async (url, token) => {
  if (token) {
    const body = {}
    const client = Client.create(token)
    const rsp = await client.post(url, body)
    return rsp.data
  } else {
    throw new Error('token is undefiend')
  }
}

export function useAggregationStates (token) {
  const { data, error } = useSWR(['admin/reports/aggregationstates', token], fetcher)
  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}
