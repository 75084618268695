import { Decimal } from 'decimal.js-light'
import Loading from '../Loading'
import { useDailySummaries } from '../../data/DailySummary'
import { startOfTodayGMT8 } from '../../utils/Utils'
import Statistic from './Statistic'
import { Cell, Grid } from 'baseui/layout-grid'
import { Card, StyledBody } from 'baseui/card'

function sumBy (data, by) {
  return data.reduce((total, x) => new Decimal(by(x)).add(total), new Decimal(0))
}

function Summary ({ currency, data }) {
  const totalTransactions = sumBy(data, x => x.totalTransactions)
  const totalBet = sumBy(data, x => x.totalBet)
  const totalWin = sumBy(data, x => x.totalWin)
  const netWin = totalBet.sub(totalWin)
  const RTP = totalWin.div(totalBet).times(100).toNumber().toFixed(2)
  return (
    <Grid gridColumns={5} gridGaps={10} gridGutters={5}>
      <Cell span={[5, 2, 1]}>
        <Statistic title='Daily transactions' data={totalTransactions.toNumber()} />
      </Cell>
      <Cell span={[5, 2, 1]}>
        <Statistic title='Daily bets' prefix={currency} data={totalBet.toNumber()} />
      </Cell>
      <Cell span={[5, 2, 1]}>
        <Statistic title='Daily win' prefix={currency} data={totalWin.toNumber()} />
      </Cell>
      <Cell span={[5, 2, 1]}>
        <Statistic title='Daily revenue' prefix={currency} data={netWin.toNumber()} />
      </Cell>
      <Cell span={[5, 2, 1]}>
        <Statistic title='Daily RTP' subfix='%' data={RTP} />
      </Cell>
    </Grid>
  )
}

const FullSizeBox = ({ children }) => {
  return (
    <Grid gridColumns={1} gridGaps={10}>
      <Cell span={1}>
        <Card>
          <StyledBody>
            {children}
          </StyledBody>
        </Card>
      </Cell>
    </Grid>
  )
}

export const TodaySummary = ({ token }) => {
  const loader = useDailySummaries(token, {
    startDate: startOfTodayGMT8().toUTC().toString(),
    endDate: startOfTodayGMT8().plus({ day: 1 }).toUTC().toString()
  })

  const data = loader.data?.items || []
  if (loader.isLoading) {
    return (
      <FullSizeBox>
        <Loading label='Data is loading' />
      </FullSizeBox>
    )
  }
  if (!loader.isLoading && data.length === 0) {
    return (
      <FullSizeBox>
        <div>No data for today</div>
      </FullSizeBox>
    )
  }
  const currencies = {}
  for (let i = 0; i < data.length; i++) {
    const x = data[i]
    if (currencies[x.currency]) {
      currencies[x.currency].push(x)
    } else {
      currencies[x.currency] = [x]
    }
  }
  const summaries = []
  for (const k in currencies) {
    summaries.push(<Summary key={k} currency={k} data={currencies[k]} />)
  }
  return summaries
}
