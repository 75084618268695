import useSWR from 'swr'
import { Grid, Cell, BEHAVIOR } from 'baseui/layout-grid'
import { useLocation } from 'react-router-dom'
import * as Client from '../data/Client'
import { useUserSummaries } from '../data/UserSummary'
import { AppLayout } from '../components/AppLayout'
import { GameTransation } from '../components/GameTransaction/Detail'
import { SimpleUserSummaryTable } from '../components/reports/UserSummaryTable'
import { useContext, useState } from 'react'
import { AppContext } from '../context/AppContext'
import Loading from '../components/Loading'

const fetcher = async (url, token, id, game) => {
  if (token) {
    const body = {
      id,
      game
    }
    const client = Client.create(token)
    const rsp = await client.post(url, body)
    return rsp.data
  } else {
    throw new Error('token is undefiend')
  }
}

function useGameTransaction (token, queryArgs) {
  const { data, error } = useSWR([
    'admin/slots/transaction',
    token,
    queryArgs.id,
    queryArgs.game], fetcher)
  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}

export default function GameTransactionDetail () {
  const { state: { user } } = useContext(AppContext)
  const location = useLocation()
  const qs = new URLSearchParams(location.search)
  const [queryArgs] = useState({
    id: qs.get('id'),
    user: qs.get('u'),
    game: qs.get('g')
  })
  const transaction = useGameTransaction(user.token, queryArgs)
  const summaries = useUserSummaries(user.token, queryArgs)
  return (
    <AppLayout title='Game Transaction Detail'>
      <Grid behavior={BEHAVIOR.fluid} gridGaps={50}>
        <Cell span={[12, 12, 5]}>
          {transaction.isError && <div>Error</div>}
          {transaction.isLoading && <Loading label='Loading transaction detail' />}
          {!transaction.isLoading && <GameTransation transaction={transaction.data} />}
        </Cell>
        <Cell span={[12, 12, 7]}>
          {summaries.isError && <div>Error</div>}
          {summaries.isLoading && <Loading label='Loading user summary' />}
          {!summaries.isLoading && <SimpleUserSummaryTable loader={summaries} />}
        </Cell>
      </Grid>
    </AppLayout>
  )
}
