import { Decimal } from 'decimal.js-light'
import { Bar, Line } from 'react-chartjs-2'
import { map, groupBy, reduce } from 'lodash'
import { Tabs, Tab } from 'baseui/tabs-motion'
import * as humanFormat from 'human-format'
import { DateTime } from 'luxon'
import { TIME_ZONES, namedColor, CHART_COLORS, convertToUSD, startOfTodayGMT8 } from '../../utils/Utils'
import { useState } from 'react'
import { styled, useStyletron } from 'baseui'
import { Grid, Cell } from 'baseui/layout-grid'
import { useDailySummaries } from '../../data/DailySummary'
import { Select, SIZE } from 'baseui/select'

const options = {
  interaction: {
    mode: 'index',
    intersect: false
  },
  scales: {
    y: {
      grid: {
        display: true,
        drawBorder: false
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  },
  plugins: {
    legend: { display: false }
  },
  elements: {
    point: { radius: 0 }
  }
}

const TabLabel = styled('div', {
  color: '#5f6368'
})

const TabNumber = styled('div', {
  fontWeight: 600,
  fontSize: '1.4em',
  lineHeight: '1.4em',
  marginTop: '0.2em'
})

function sumBy (data, by) {
  return data.reduce((total, x) => new Decimal(by(x)).add(total), new Decimal(0))
}

function getLabels (groupedItems) {
  return Object.keys(groupedItems).map(x => DateTime.fromISO(x).setZone(TIME_ZONES.Taipei).toFormat('dd/MM'))
}

function getTotalTransactions (groupedItems) {
  const totalTransactions = map(groupedItems, (v, k) => sumBy(v, x => x.totalTransactions).toNumber())
  const gameIds = new Set()
  const groupedGames = map(groupedItems, (v, k) => reduce(v, (s, x) => {
    const last = s[x.gameId] || 0
    s[x.gameId] = x.totalTransactions + last
    gameIds.add(x.gameId)
    return s
  }, {}))
  const datasets = Array.from(gameIds).map((id, i) => {
    const color = namedColor(i)
    return {
      label: id,
      data: groupedGames.map(x => x[id] || 0),
      backgroundColor: color,
      borderColor: color
    }
  })
  datasets.push({
    label: 'Total',
    type: 'bar',
    data: totalTransactions,
    backgroundColor: CHART_COLORS.blue,
    borderColor: CHART_COLORS.blue
  })
  return {
    labels: getLabels(groupedItems),
    datasets: datasets
  }
}

function getBetWins (currency, groupedItems) {
  const totalBets = map(groupedItems, (v, k) => convertToUSD(currency, sumBy(v, x => x.totalBet)))
  const totalWins = map(groupedItems, (v, k) => convertToUSD(currency, sumBy(v, x => x.totalWin)))
  return {
    labels: getLabels(groupedItems),
    datasets: [
      {
        label: 'Total Bet (USD)',
        data: totalBets,
        backgroundColor: CHART_COLORS.blue,
        borderColor: CHART_COLORS.blue
      },
      {
        label: 'Total Win (USD)',
        data: totalWins,
        backgroundColor: CHART_COLORS.red,
        borderColor: CHART_COLORS.red
      }
    ]
  }
}

function getNetWins (currency, groupedItems) {
  const netWins = map(groupedItems, (v, k) => {
    return convertToUSD(currency, sumBy(v, x => new Decimal(x.totalBet).minus(new Decimal(x.totalWin)).toNumber()))
  })
  return {
    labels: getLabels(groupedItems),
    datasets: [{
      label: 'Net Win (USD)',
      data: netWins,
      backgroundColor: x => {
        if (x.raw >= 0) {
          return CHART_COLORS.blue
        } else {
          return CHART_COLORS.red
        }
      }
    }]
  }
}

function Summary ({ currency, items }) {
  const [activeKey, setActiveKey] = useState('0')
  const totalBet = sumBy(items, x => x.totalBet)
  const totalWin = sumBy(items, x => x.totalWin)
  const netWin = totalBet.sub(totalWin)
  const grouped = groupBy(items, x => x.aggregatedDate)

  const totalTransactionsTitle = (
    <div>
      <TabLabel>Transactions</TabLabel>
      <TabNumber>{humanFormat(sumBy(items, x => x.totalTransactions).toNumber())}</TabNumber>
    </div>
  )

  const totalBetWinTitle = (
    <div>
      <TabLabel>Bet/Win (USD)</TabLabel>
      <TabNumber>{humanFormat(convertToUSD(currency, totalBet))}/{humanFormat(convertToUSD(currency, totalWin))}</TabNumber>
    </div>
  )

  const winLoseTitle = (
    <div>
      <TabLabel>Win/Lose (USD)</TabLabel>
      <TabNumber>{humanFormat(convertToUSD(currency, netWin))}</TabNumber>
    </div>
  )

  return (
    <Tabs
      activeKey={activeKey}
      onChange={({ activeKey }) => {
        setActiveKey(activeKey)
      }}
      activateOnFocus
    >
      <Tab title={totalTransactionsTitle}><Line data={getTotalTransactions(grouped)} options={options} /></Tab>
      <Tab title={totalBetWinTitle}><Bar data={getBetWins(currency, grouped)} options={options} /></Tab>
      <Tab title={winLoseTitle}><Bar data={getNetWins(currency, grouped)} options={options} /></Tab>
    </Tabs>
  )
}

const currencies = [
  { label: 'KSH', id: 'KSH' },
  { label: 'NGN', id: 'NGN' },
  { label: 'BRL', id: 'BRL' }
]

const dateRanges = [
  { label: 'Last 7 days', id: '7' },
  { label: 'Last 14 days', id: '14' },
  { label: 'Last 28 days', id: '28' }
]

export const WinLoseCharts = ({ token }) => {
  const [css, theme] = useStyletron()
  const [currency, setCurrency] = useState(currencies[0])
  const [pastDays, setPastDays] = useState(dateRanges[1])
  const summaries = useDailySummaries(token, {
    startDate: startOfTodayGMT8().minus({ day: pastDays.id }).toUTC().toString(),
    endDate: startOfTodayGMT8().plus({ day: 1 }).toUTC().toString(),
    size: 1000
  })
  const data = summaries.data?.items || []
  const items = data.filter(x => x.currency === currency.id).reverse()
  return (
    <div className={css({ border: `2px solid ${theme.colors.borderOpaque}` })}>
      <Summary key={currency.id} currency={currency.id} items={items} />
      <Grid gridGaps={0} gridGutters={0} gridMargins={0}>
        <Cell span={4}>
          <Select
            options={currencies}
            value={[currency]}
            clearable={false}
            size={SIZE.compact}
            isLoading={summaries.isLoading}
            onChange={params => setCurrency(params.option)}
          />
        </Cell>
        <Cell span={8}>
          <Select
            options={dateRanges}
            value={[pastDays]}
            clearable={false}
            size={SIZE.compact}
            isLoading={summaries.isLoading}
            onChange={params => setPastDays(params.option)}
          />
        </Cell>
      </Grid>

    </div>
  )
}
