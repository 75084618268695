import { useStyletron } from 'baseui'
import { StyledLink as Link } from 'baseui/link'
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic'
import { Decimal } from 'decimal.js-light'
import { numberFormat, currencyFormat } from '../../utils/Number'
import { formatDate } from '../../utils/Utils'
import { TotalSummary } from './TotalSummary'

const overrides = {
  TableBodyRow: {
    style: ({ $theme, $rowIndex }) => ({
      backgroundColor:
        $rowIndex % 2
          ? $theme.colors.backgroundPrimary
          : $theme.colors.backgroundSecondary,
      ':hover': {
        backgroundColor: $theme.colors.backgroundTertiary
      }
    })
  }
}

export function UserSummaryTable ({ loader, clickNextHandler }) {
  const [css, theme] = useStyletron()
  const data = loader.data?.items || []
  return (
    <div>
      <div>
        <TotalSummary data={data} />
      </div>
      <TableBuilder
        overrides={overrides}
        isLoading={loader.isLoading}
        data={data}
        emptyMessage='No data'
      >
        <TableBuilderColumn header='Date'>
          {x => formatDate(x.aggregatedDate)}
        </TableBuilderColumn>
        <TableBuilderColumn header='CCY'>
          {x => x.currency}
        </TableBuilderColumn>
        <TableBuilderColumn header='Game'>
          {x => x.gameId}
        </TableBuilderColumn>
        <TableBuilderColumn header='Merchant'>
          {x => x.merchantId}
        </TableBuilderColumn>
        <TableBuilderColumn header='User'>
          {x => x.userId}
        </TableBuilderColumn>
        <TableBuilderColumn header='# of TXN'>
          {x => numberFormat(x.totalTransactions)}
        </TableBuilderColumn>
        <TableBuilderColumn header='Total Bet'>
          {x => currencyFormat(x.totalBet)}
        </TableBuilderColumn>
        <TableBuilderColumn header='Total Win'>
          {x => currencyFormat(x.totalWin)}
        </TableBuilderColumn>
        <TableBuilderColumn header='Net Win'>
          {x => {
            const totalBet = new Decimal(x.totalBet)
            const totalWin = new Decimal(x.totalWin)
            const amount = totalBet.sub(totalWin).toNumber()
            let color
            if (amount < 0) color = theme.colors.negative
            return (
              <div className={css({
                fontWeight: '700',
                textAlign: 'right',
                color: color
              })}
              >
                {currencyFormat(amount)}
              </div>
            )
          }}
        </TableBuilderColumn>
        <TableBuilderColumn header='...'>
          {x => {
            return (
              <>
                <Link href={`/reports/usersummary?u=${x.userId}`}>Summary</Link>{' | '}
                <Link href={`/slots/transactions?u=${x.userId}`}>History</Link>
              </>
            )
          }}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  )
}

export function SimpleUserSummaryTable ({ loader, clickNextHandler }) {
  const [css, theme] = useStyletron()
  const data = loader.data?.items || []
  return (
    <div>
      <div>
        <TotalSummary data={data} />
      </div>
      <TableBuilder
        overrides={overrides}
        isLoading={loader.isLoading}
        data={data}
        emptyMessage='No data'
      >
        <TableBuilderColumn header='Date'>
          {x => formatDate(x.aggregatedDate)}
        </TableBuilderColumn>
        <TableBuilderColumn header='CCY'>
          {x => x.currency}
        </TableBuilderColumn>
        <TableBuilderColumn header='# of TXN'>
          {x => numberFormat(x.totalTransactions)}
        </TableBuilderColumn>
        <TableBuilderColumn header='Total Bet'>
          {x => currencyFormat(x.totalBet)}
        </TableBuilderColumn>
        <TableBuilderColumn header='Total Win'>
          {x => currencyFormat(x.totalWin)}
        </TableBuilderColumn>
        <TableBuilderColumn header='Net Win'>
          {x => {
            const totalBet = new Decimal(x.totalBet)
            const totalWin = new Decimal(x.totalWin)
            const amount = totalBet.sub(totalWin).toNumber()
            let color
            if (amount < 0) color = theme.colors.negative
            return (
              <div className={css({
                fontWeight: '700',
                textAlign: 'right',
                color: color
              })}
              >
                {currencyFormat(amount)}
              </div>
            )
          }}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  )
}
