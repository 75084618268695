import { useStyletron } from 'baseui'
import { Card, StyledBody } from 'baseui/card'
import { isNumber, numberFormat } from '../../utils/Number'

const Statistic = ({ title, data, prefix, subfix }) => {
  const [css, theme] = useStyletron()
  let value = data
  let color = theme.colors.contentPrimary
  if (isNumber(data)) {
    value = numberFormat(data)
    if (data < 0) {
      color = theme.colors.negative
    }
  }
  return (
    <Card>
      <h1 className={css({
        color: color,
        margin: '0.3em 0'
      })}
      >
        {prefix && prefix + ' '}{value}{subfix && subfix}
      </h1>
      <StyledBody className={css({
        color: theme.colors.contentTertiary
      })}
      >
        {title}
      </StyledBody>
    </Card>
  )
}

export default Statistic
