import Decimal from 'decimal.js-light'
import { DateTime } from 'luxon'

export const startOfDay = date => {
  if (date) {
    date.setHours(0, 0, 0, 0)
  }
  return date
}

export const addDays = (date, days) => {
  if (date) {
    date.setDate(date.getDate() + days)
  }
  return date
}

const datetimeFormatOptions = {
  weekday: 'short',
  // timeZone: 'Africa/Nairobi',
  day: '2-digit',
  month: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

const yearMonthOnlyFormatOptions = {
  year: 'numeric',
  month: '2-digit'
}

const dateFormatOptions = {
  timeZone: 'Asia/Singapore'
}

const formatDateTimeWith = options => date => {
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString('en-US', options)
  } else if (date instanceof Date) {
    return date.toLocaleDateString('en-US', options)
  }
  return date
}

export const formatDateTime = date => formatDateTimeWith(datetimeFormatOptions)(date)

export const formatDate = date => formatDateTimeWith(dateFormatOptions)(date)

export const formatDateAsYearMonth = date => formatDateTimeWith(yearMonthOnlyFormatOptions)(date)

export function isEmptyOrSpaces (str) {
  return str === null || str === undefined || str.match(/^ *$/) !== null
}

export const TIME_ZONES = {
  Taipei: 'Asia/Taipei',
  Nairobi: 'Africa/Nairobi'
}

export const startOfTodayGMT8 = () => DateTime.now().setZone(TIME_ZONES.Taipei).startOf('day').toUTC()

export function generateLightColorRgb () {
  const red = Math.floor((1 + Math.random()) * 256 * 0.5)
  const green = Math.floor((1 + Math.random()) * 256 * 0.5)
  const blue = Math.floor((1 + Math.random()) * 256 * 0.5)
  return 'rgb(' + red + ', ' + green + ', ' + blue + ')'
  // const color = 'hsl(' + Math.random() * 360 + ', 100%, 45%)'
  // return color
}

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  purple: 'rgb(153, 102, 255)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  grey: 'rgb(201, 203, 207)'
}

const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.purple,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.grey
]

export function namedColor (index) {
  return NAMED_COLORS[index % NAMED_COLORS.length]
}

// exchange rate
export const exchangeRates = {
  FUN: new Decimal(0),
  KSH: new Decimal(0.0093),
  NGN: new Decimal(0.0024),
  BRL: new Decimal(0.19)
}

export function convertToUSD (currency, amount) {
  return parseFloat(amount.times(exchangeRates[currency]).toNumber().toFixed(2))
}
