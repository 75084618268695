import { Win } from '../Win'

export function Wins ({ transaction, lineBet }) {
  return (
    <div>
      {transaction.win.map((x, i) => {
        return (
          <div key={i} className='winning-line'>
            <Win transaction={transaction} win={x} reel={5} row={3} />
          </div>
        )
      })}
      {transaction.state.trigger && <Win transaction={transaction} win={transaction.state.trigger} reel={5} row={3} />}
    </div>
  )
}
